body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoom-container {
  width: 100%;
  height: 100vh;
}

.zoom-sm {
  transform: scale(0.67);
  transform-origin: 0 0;
  width: 149.25%;
  height: 149.25%;
}

.zoom-md {
  transform: scale(0.75);
  transform-origin: 0 0;
  width: 133.33%; 
  height: 133.33%;
}

.zoom-lg {
  transform: scale(1);
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.scaled-container {
  transform: scale(0.85);
  transform-origin: 0 0;
  width: 117.64%;
  height: 117.64%;
  overflow: auto;
}

.scaled-container thead tr th,
.scaled-container tbody tr td {
  padding: 5px 0;
}

.table-light-theme tbody tr:nth-child(even) {
  background-color: #dedee3;
}

.table-light-theme tbody tr:nth-child(even):hover {
  background-color: #cacace;
}

.table-dark-theme tbody tr:nth-child(even) {
  background-color: #444444;
}

.table-dark-theme tbody tr:nth-child(even):hover {
  background-color: #4b4b4b;
}

.version-date {
  font-size: 20px;
}
